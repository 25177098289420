import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import '../img/icons/fontawesome'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import { Container, Heading, Text } from '../components/UI'

export const BlogPageTemplate = ({
  title,
  date,
  html,
  description
}) => (
  <>
    <Helmet>
      <title>{title} - SecSafeConsult</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
    </Helmet>
    <Container maxWidth="content" mx="auto" px="lg">
      <Container textAlign="center" mt={["0", "xxxl"]}>
        <Heading highlight="center">{title}</Heading>
        <Text>{date}</Text>
      </Container>
    </Container>
    <Container maxWidth="600px" mx="auto" mt="xxl" mb="xxxxl" px="xl">
      <Container dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  </>
)

BlogPageTemplate.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
}

const BlogPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <BlogPageTemplate
        title={frontmatter.title}
        date={frontmatter.date}
        description={frontmatter.postDescription}
        html={html}
      />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageTemplate($id: String!) {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }, id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        postDescription
      }
    }
  }
`
